html, body, #root {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./images/CoffeeWifiBG1920.jpg');
}
.LinkContainer {
  /* background-image: url('./images/hub.jpg'); */
  background-image: url('./images/2600x1200.jpg');
  background-size: cover;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input:focus {
  outline: none;
}

input.error,
.select.error * {
  border-color: red;
}
span.error,
.error.select p, .error.select svg {
  color: red;
}

@media screen and (max-width: 600px) {
  body {
    background-image: url('./images/mobile2.jpg');
    background-repeat: no-repeat;
    margin: 0;
  }
  .LinkContainer {
    background-image: url('./images/500x900.jpg');
    background-size: cover;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
