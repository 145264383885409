html, body, #root {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(/static/media/CoffeeWifiBG1920.3ce6df51.jpg);
}
.LinkContainer {
  /* background-image: url('./images/hub.jpg'); */
  background-image: url(/static/media/2600x1200.f4825d67.jpg);
  background-size: cover;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input:focus {
  outline: none;
}

input.error,
.select.error * {
  border-color: red;
}
span.error,
.error.select p, .error.select svg {
  color: red;
}

@media screen and (max-width: 600px) {
  body {
    background-image: url(/static/media/mobile2.2bddfc6b.jpg);
    background-repeat: no-repeat;
    margin: 0;
  }
  .LinkContainer {
    background-image: url(/static/media/500x900.798e546c.jpg);
    background-size: cover;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.villa-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-image: url(/static/media/villaBGMobile.b3e11610.png);
  background-repeat: no-repeat;
  background-size: cover;
  color: #FFFEF8;
}

.app-logo {
  width: 7.6875em;
  margin: auto;
  display: block;
  margin-top: 4.4375em;
}

.app-slogan {
  font-size: 1.3125em;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 450;
  letter-spacing: 0.1em;
}

.app-bottom {
  display: flex;
  padding-bottom: 5.4375em;
  align-items: center;
  justify-content: flex-start;
  height: 9em;
  flex-direction: column;
}

.membership-btn {
  font-size: 1.125em;
  background-color: transparent;
  border: 1px solid #FFFEF8;
  border-radius: 3.33em;
  color: inherit;
  height: 2.38em;
  width: 11.5em;
  display: block;
  font-family: inherit;
  margin: 1em;
}

.membership-btn:hover {
  background-color: #FFFEF8;
  color: #353D2F;
}

.membership-msg {
  font-size: 1.125em;
  padding: 0 4.5em;
  text-align: center;

}

.villa-checkbox-wrapper {
  display: flex;
  align-items: center;
}

.villa-checkbox-wrapper .checkbox.checked {
  color: white;
}

.villa-checkbox-wrapper svg {
  fill: white;
}

.villa-checkbox-wrapper a {
  color: inherit;
}

.villa-error {
  color: #a93535;
  font-weight: 600;
}

@media screen and (min-width: 767px) {
  .villa-container {
    background-image: url(/static/media/villaBGDesktop.2ed3c0fd.png);

  }

  .villa-checkbox-wrapper {
    font-size: 1.3em;
  }

  .app-bottom {
    height: 11em;
  }

  .app-logo {
    width: 9.6875em;
    margin-top: 7.125em;
  }

  .app-slogan {
    font-size: 2.4em;
  }

  .membership-btn {
    font-size: 1.375em;
  }

  .membership-msg {
    font-size: 1.375em;
  }
}

