.villa-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-image: url('../../images/villaBGMobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  color: #FFFEF8;
}

.app-logo {
  width: 7.6875em;
  margin: auto;
  display: block;
  margin-top: 4.4375em;
}

.app-slogan {
  font-size: 1.3125em;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 450;
  letter-spacing: 0.1em;
}

.app-bottom {
  display: flex;
  padding-bottom: 5.4375em;
  align-items: center;
  justify-content: flex-start;
  height: 9em;
  flex-direction: column;
}

.membership-btn {
  font-size: 1.125em;
  background-color: transparent;
  border: 1px solid #FFFEF8;
  border-radius: 3.33em;
  color: inherit;
  height: 2.38em;
  width: 11.5em;
  display: block;
  font-family: inherit;
  margin: 1em;
}

.membership-btn:hover {
  background-color: #FFFEF8;
  color: #353D2F;
}

.membership-msg {
  font-size: 1.125em;
  padding: 0 4.5em;
  text-align: center;

}

.villa-checkbox-wrapper {
  display: flex;
  align-items: center;
}

.villa-checkbox-wrapper .checkbox.checked {
  color: white;
}

.villa-checkbox-wrapper svg {
  fill: white;
}

.villa-checkbox-wrapper a {
  color: inherit;
}

.villa-error {
  color: #a93535;
  font-weight: 600;
}

@media screen and (min-width: 767px) {
  .villa-container {
    background-image: url('../../images/villaBGDesktop.png');

  }

  .villa-checkbox-wrapper {
    font-size: 1.3em;
  }

  .app-bottom {
    height: 11em;
  }

  .app-logo {
    width: 9.6875em;
    margin-top: 7.125em;
  }

  .app-slogan {
    font-size: 2.4em;
  }

  .membership-btn {
    font-size: 1.375em;
  }

  .membership-msg {
    font-size: 1.375em;
  }
}
